<template>
  <div class="container mt-5   px-md-5" style="margin-left:auto; margin-right:auto;">
    <div class=" mb-4">
        <h2 class="text-left" style="text-transform: capitalize;">{{title}}</h2>
    </div>
    <div class="container-fluid p-0">
      <button @click="showModal(1)" class="btn-primary"><i class="fa fa-plus mr-2"></i>Tarjeta</button>
    </div>
    <div class="my-4 mb-2">
      <!-- <h2 class="text-left">Mis solicitudes</h2> -->
    </div>
    
    
    <div class="bg-gray ">
      <b-table 
          hover
          responsive 
          :items="table_list" 
          :fields="fields" 
          :current-page="currentPage" 
          :per-page="perPage" 
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
      >
      <template #cell(name)="row">
          {{ row.value.first }} {{ row.value.last }}
      </template>
              <template #cell(fecha_inicio)="data">
                  <span  >{{ data.item.fecha_fin != null ? $moment(data.item.fecha_inicio).utc().format("DD-MM-YYYY") : '' }}</span>
              </template>
              <template #cell(fecha_fin)="data">
                  <span  >{{ data.item.fecha_fin != null ? $moment(data.item.fecha_fin).utc().format("DD-MM-YYYY") : '' }}</span>
              </template>
              <template #cell(action)="data">
                  <button @click="showModal(2, data.item)" class="btn-white-outline-small mx-1"><i class="fa fa-pen"></i></button>
                  <button @click="$router.push('NAO_Detalles/'+data.item.id)" class="btn-white-outline-small mx-1"><i class="fa fa-edit"></i></button>
                  <button @click="deleteItem(data.item)" class="btn-primary-small mx-1"><i class="fa fa-trash"></i></button>
              </template>
      <template #row-details="row">
          <b-card>
          <ul>
              <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
          </b-card>
      </template>
      </b-table>
        <div>
          
      </div>
    </div>
    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" hide-goto-end-buttons hide-ellipsis prev-text="Prev" next-text="Next" align="right"></b-pagination>


    <!-- show details and edit -->    
    
  <b-modal ref="modal-item2" hide-footer >
    <template #modal-title>
      {{modalText}}
    </template>

    <template>
      <form @submit.prevent="saveData()" style="padding:10px">
        <b-tabs content-class="mt-3">
          <b-tab title="General">

            <div class="row">
              <div class="col-9">
                <label for="">Nombre</label>
                <input required class="form-control" v-model="row.titulo" type="text" name="" id="">
              </div>
              <div class="col-3">
                <b-form-checkbox class="mt-4" size="lg" id="checkbox-2" v-model="row.privado" name="checkbox-2" value="1" unchecked-value="0" > 
                    <span for="observaciones" class="h6">Privado</span>
                </b-form-checkbox>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12">
                <label for="">Descripción</label>
                <!-- <input required class="form-control" v-model="row.descripcion" type="text" name="" id=""> -->
                <vue-editor class="form-control p-0 m-0" style="height:150px;" v-model="row.descripcion" :editorOptions="editorOptions"/>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12">
                <label for="">Categoría</label>
                <!-- <input class="form-control" v-model="row.urlimagen" type="text" name="" id=""> -->
                  <b-form-select required v-model="row.subcategoria_id" class="mb-3 form-control">
                      <b-form-select-option class="text-center" v-for="item of subcategorias" 
                      :value="item.id" :key="item.id">{{ item.titulo }} </b-form-select-option>
                  </b-form-select>            
              </div>
            </div>
            <div class="row mt-1">

              <div class="col-12">
                <label for="">Texto boton tarjeta</label>
                <input class="form-control" v-model="row.texto_boton_tarjeta" type="text" name="" id="">
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12">
                <label for="">Documento descargable (desde tarjeta)</label><br>
                <input type="file" id="file6" ref="file6" v-on:change="onFileSelected('descargable',6)"/><br>
                <input class="form-control" v-model="row.documento_descargable_tar" type="text" name="" id="">
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12">
                <label for="">Imagen</label><br>
                <input type="file" id="file1" ref="file1" v-on:change="onFileSelected('cruceros',1)"/><br>
                <input class="form-control" v-model="row.urlimagen" type="text" name="" id="">
              </div>
            </div>
            <!-- itinerario cliente -->
            <div class="row mt-1">
              <div class="col-12">
                <label for="">Itinerario cliente</label><br>
                <input type="file" id="file10" ref="file10" v-on:change="onFileSelected('itinerario',10)"/><br>
                <input class="form-control" v-model="row.itinerario_cliente" type="text" name="" id="">
              </div>
            </div>
          </b-tab>

          <b-tab title="Detalle producto">
            <div class="row mt-1">
              <div class="col-12">
                <label for="">Documento editable</label><br>
                <input type="file" id="file2" ref="file2" v-on:change="onFileSelected('editable',2)"/><br>
                <input class="form-control" v-model="row.documento_word" type="text" name="" id="">
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-12">
                <label for="">Título</label>
                <input  class="form-control" v-model="row.detalle_titulo" type="text" name="" id="">
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12">
                <label for="">Descripción</label>
                <input  class="form-control" v-model="row.detalle_descripcion" type="text" name="" id="">
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12">
                <label for="">imagen</label><br>
                <input type="file" id="file3" ref="file3" v-on:change="onFileSelected('crucerosdetalle',3)"/><br>
                <input  class="form-control" v-model="row.detalle_urlimagen" type="text" name="" id="">
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12">
                <label for="">texto boton1</label>
                <input  class="form-control" v-model="row.detalle_boton1_texto" type="text" name="" id="">
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12">
                <label for="">ruta boton1</label><br>
                <input type="file" id="file7" ref="file7" v-on:change="onFileSelected('archivodesargable',7)"/><br>
                <input  class="form-control" v-model="row.detalle_boton1_ruta" type="text" name="" id="">
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12">
                <label for="">texto boton2</label>
                <input  class="form-control" v-model="row.detalle_boton2_texto" type="text" name="" id="">
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12">
                <label for="">ruta boton2</label><br>
                <input type="file" id="file8" ref="file8" v-on:change="onFileSelected('archivodesargable',8)"/><br>
                <input  class="form-control" v-model="row.detalle_boton2_ruta" type="text" name="" id="">
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12">
                <label for="">Titulo banner1</label>
                <input  class="form-control" v-model="row.detalle_banner1_titulo" type="text" name="" id="">
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12">
                <label for="">descripción banner1</label>
                <input  class="form-control" v-model="row.detalle_banner1_descripcion" type="text" name="" id="">
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-12">
                <label for="">imagen banner1</label><br>
                <input type="file" id="file4" ref="file4" v-on:change="onFileSelected('crucerosimagenbanner1',4)"/><br>
                <input  class="form-control" v-model="row.detalle_banner1_urlimagen" type="text" name="" id="">
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12">
                <label for="">texto boton banner1</label>
                <input  class="form-control" v-model="row.detalle_banner1_boton" type="text" name="" id="">
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12">
                <label for="">ruta boton banner1</label>
                <input  class="form-control" v-model="row.detalle_banner1_ruta" type="text" name="" id="">
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12">
                <label for="">texto banner2</label>
                <input  class="form-control" v-model="row.detalle_banner2_texto" type="text" name="" id="">
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12">
                <label for="">imagen banner2</label><br>
                <input type="file" id="file5" ref="file5" v-on:change="onFileSelected('crucerosimagenbanner2',5)"/><br>
                <input  class="form-control" v-model="row.detalle_banner2_urlimagen" type="text" name="" id="">
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12">
                <label for="">texto boton banner2</label>
                <input  class="form-control" v-model="row.detalle_banner2_boton" type="text" name="" id="">
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12">
                <label for="">ruta boton banner2</label>
                <input  class="form-control" v-model="row.detalle_banner2_ruta" type="text" name="" id="">
              </div>
            </div>

       
          </b-tab>
        </b-tabs>
        <div class="row mt-4">
          <div class="col-12">
            <b-button type="submit" class="btn-primary-small mx-1 float-right ml-3">Guardar</b-button>
            <b-button @click="$refs['modal-item2'].hide()" class="btn-white-outline-small float-right">Cancelar</b-button>
          </div>
        </div>
      </form>
    </template>
  </b-modal>  
  </div>
</template>

<script>
// import FormVue from "@/components/Form.vue";
import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";
export default {
  components: {
    VueEditor
    // FormVue,
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      editorOptions : {
        modules: {
          toolbar: false,
        },
      },
      selected: null,
        categorias: [
          { id: 1, text: 'Categoría' },
          { id: 2, text: 'This is First option' },
          { id: 3, text: 'Selected Option' },
          { id: 4, text: 'This is an option with object value' },
          { id: 5, text: 'This one is disabled'}
        ],
        subcategorias: [
        ],
        Producto: [
          { id: 1, text: 'Producto' },
          { id: 2, text: 'This is First option' },
          { id: 3, text: 'Selected Option' },
          { id: 4, text: 'This is an option with object value' },
          { id: 5, text: 'This one is disabled'}
        ],
      perPage: 25,
      currentPage: 1,

      table: "tarjetas",
      modalText: 'Nueva Ficha',
      title: 'luxury collection',
      items: [],

      table_list: [],
      row:{},
      categoria_id: 5,
      fields: [
          { key: 'id', label: 'id', sortable: true, sortDirection: 'desc', class: 'text-center' },
          { key: 'titulo', label: 'Titulo', sortable: true, class: 'text-center' },
          { key: 'subcategoria', label: 'Categoría', sortable: true, class: 'text-center' },
          // { key: 'fecha_inicio', label: 'fecha inicio', sortable: true, class: 'text-center' },
          // { key: 'fecha_fin', label: 'fecha fin', sortable: true, class: 'text-center' },
          // { key: 'estado', label: 'Estado', sortable: true, class: 'text-center' },
          { key: 'action', label: 'Acciones',class: 'text-center'},

        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, { value: 100, text: 100 }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      file1: null,
      filename1: null,
      file2: null,
      filename2: null,
      file3: null,
      filename3: null,
      file4: null,
      filename4: null,
      file5: null,
      filename5: null,
      file6: null,
      filename6: null,
      file7: null,
      filename7: null,
      file8: null,
      filename8: null, 
      file10: null,
      filename10: null,
    };
  },
  async mounted() {
    this.$isLoading(true)
    await this.getData();
    await this.getSubcategories();
    this.$isLoading(false)

  },
  methods: {
    Clear(){
      this.file1= null
      this.filename1= null
      this.file2= null
      this.filename2= null
      this.file3= null
      this.filename3= null
      this.file4= null
      this.filename4= null
      this.file5= null
      this.filename5= null
      this.file6= null
      this.filename6= null
      this.file7= null
      this.filename7= null
      this.file8= null
      this.filename8= null
      this.file10= null
      this.filename10= null
      this.row = {}
    },
    onFileSelected(type, item){
      if(item==1){
        this.file1 = event.target.files[0];
        this.filename1 = Math.floor(Math.random() * 9999999999999 + 1);
        this.filename1 = type + '_' + this.filename1+ '.jpg';
      }
      if(item==2){
        this.file2 = event.target.files[0];
        let extension = this.file2.name.split('.')[1];
        this.filename2 = Math.floor(Math.random() * 9999999999999 + 1);
        this.filename2 = type + '_' + this.filename2 +'.'+ extension;
      }
      if(item==3){
        this.file3 = event.target.files[0];
        let extension = this.file3.name.split('.')[1];
        this.filename3 = Math.floor(Math.random() * 9999999999999 + 1);
        this.filename3 = type + '_' + this.filename3 +'.'+ extension;
      }
      if(item==4){
        this.file4 = event.target.files[0];
        let extension = this.file4.name.split('.')[1];
        this.filename4 = Math.floor(Math.random() * 9999999999999 + 1);
        this.filename4 = type + '_' + this.filename4 +'.'+ extension;
      }
      if(item==5){
        this.file5 = event.target.files[0];
        let extension = this.file5.name.split('.')[1];
        this.filename5 = Math.floor(Math.random() * 9999999999999 + 1);
        this.filename5 = type + '_' + this.filename5 +'.'+ extension;
      }
      if(item==6){
        this.file6 = event.target.files[0];
        let extension = this.file6.name.split('.')[1];
        this.filename6 = Math.floor(Math.random() * 9999999999999 + 1);
        this.filename6 = type + '_' + this.filename6 +'.'+ extension;
      }
      if(item==7){
        this.file7 = event.target.files[0];
        let extension = this.file7.name.split('.')[1];
        this.filename7 = Math.floor(Math.random() * 9999999999999 + 1);
        this.filename7 = type + '_' + this.filename7 +'.'+ extension;
      }
      if(item==8){
        this.file8 = event.target.files[0];
        let extension = this.file8.name.split('.')[1];
        this.filename8 = Math.floor(Math.random() * 9999999999999 + 1);
        this.filename8 = type + '_' + this.filename8 +'.'+ extension;
      }
      if(item==10){
        this.file10 = event.target.files[0];
        let extension = this.file10.name.split('.')[1];
        this.filename10 = Math.floor(Math.random() * 9999999999999 + 1);
        this.filename10 = type + '_' + this.filename10 +'.'+ extension;
      }      
    },
    showModal(type, item){
      console.log(item)
      if(type == 2){
        this.modalText = 'Editar Ficha'
        this.row = item
      }
      else{
        this.row = {}
        this.modalText = 'Nueva Ficha'
      }
      this.$refs['modal-item2'].show()
    },
    async getSubcategories() {
      try {
        let res = await this.$store.dispatch("get", { path: 'subcategorias/getAllByCategory/' + this.categoria_id});
        console.log('------------->',res)
        if(res.length>0){
          this.subcategorias = res
        }
        else{
          this.subcategorias = []
        }
      } catch (error) {
        this.subcategorias = []
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error:" + error,
        });
      }
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("get", { path: this.table + '/getAllByCategory/' + this.categoria_id});
        //console.log(res)
        if(res.length>0){
          this.table_list = res
          this.totalRows = this.table_list.length
        }
        else{
          this.table_list = []
          this.totalRows = this.table_list.length
        }
      } catch (error) {
        this.table_list = []
        this.totalRows = this.table_list.length
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error: " + error,
        });
      }
    },
    async saveData() {
      try {
        let req
        let uploadImg
        this.$isLoading(true)        
        if(this.filename1!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename1 ,file: this.file1})
          if(uploadImg == true){
            console.log('----',uploadImg)
            this.row.urlimagen = 'https://naotravelco.com/img/' + this.filename1
          }
        }
        if(this.filename2!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename2 ,file: this.file2})
          if(uploadImg == true){
            console.log('----',uploadImg)
            this.row.documento_word = 'https://naotravelco.com/img/' + this.filename2
          }
        }
        if(this.filename3!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename3 ,file: this.file3})
          if(uploadImg == true){
            console.log('----',uploadImg)
            this.row.detalle_urlimagen = 'https://naotravelco.com/img/' + this.filename3
          }
        }
        if(this.filename4!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename4 ,file: this.file4})
          if(uploadImg == true){
            console.log('----',uploadImg)
            this.row.detalle_banner1_urlimagen = 'https://naotravelco.com/img/' + this.filename4
          }
        }
        if(this.filename5!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename5 ,file: this.file5})
          if(uploadImg == true){
            console.log('----',uploadImg)
            this.row.detalle_banner2_urlimagen = 'https://naotravelco.com/img/' + this.filename5
          }
        }
        if(this.filename6!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename6 ,file: this.file6})
          if(uploadImg == true){
            console.log('----',uploadImg)
            this.row.documento_descargable_tar = 'https://naotravelco.com/img/' + this.filename6
          }
        }
        if(this.filename7!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename7 ,file: this.file7})
          if(uploadImg == true){
            console.log('----',uploadImg)
            this.row.detalle_boton1_ruta = 'https://naotravelco.com/img/' + this.filename7
          }
        }
        if(this.filename8!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename8 ,file: this.file8})
          if(uploadImg == true){
            console.log('----',uploadImg)
            this.row.detalle_boton2_ruta = 'https://naotravelco.com/img/' + this.filename8
          }
        }
        if(this.filename10!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename10 ,file: this.file10})
          if(uploadImg == true){
            console.log('----',uploadImg)
            this.row.itinerario_cliente = 'https://naotravelco.com/img/' + this.filename10
          }
        }

        if(this.row.id){
          let path = 'tarjetas/modify/'+ this.row.id
          //console.log('path', path)
          delete this.row.subcategoria
          req = await this.$store.dispatch("put", {
            path: path,
            data: this.row
          });
        }
        else{
          this.row.categoria_id = this.categoria_id
          req = await this.$store.dispatch("post", {
            path: this.table + '/new',
            data: this.row
          });
        }
        this.$isLoading(false)
        this.$swal({
          icon: "success",
          title: "Muy bien!",
          text: "Datos guardados correctamente!",
        });
        this.$refs['modal-item2'].hide()
        this.Clear()
        this.getData();
      } catch (error) {
        this.$isLoading(false)
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error, revisar datos.",
        });        
        //alert(error);
      }
    },
    ///////////////
    gotoTanks(){
      console.log('voy a ...')
      this.$router.push('/admin/gracias_solicitud')
    },

    async deleteItem(item) {
      try {
        console.log(item.id)
        const sure = await confirm(
          "¿Está seguro que desea eliminar este elemento?"
        );
        if (!sure) {
          return;
        }

        const req = await this.$store.dispatch("delete", {
          path: this.table +'/' + item.id,
        });
        this.$swal({
          icon: "success",
          title: "Muy bien!",
          text: "Registro eliminado correctamente!",
        });        
        this.getData();
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "Opps!",
          text: "Error: " + error,
        });        
        console.log(error)
        //alert(error);
      }
    },
  },
};
</script>

<style>
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
 
 

</style>